const styles = {
  container: {
    height: "100vh"
  },
  main: {
    textAlign: "center",
    marginTop: 100
  },
  title: {
    marginBottom: 50
  },
  marginTop20: {
    marginTop:20
  },
  splashContainer: {
    height: 400,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 100
  },
  splashImageContainer: { margin: "0 auto", paddingTop: 43, textAlign: "center"},
  heading: { color: "black", textAlign: "center", fontWeight: 600 },
  logo: { height: 350 }
};

export default styles;