import React, { Component, Fragment } from "react";
import queryString from "query-string";
import Api from "../Services/Api";
import { Button, Container, Typography } from "@material-ui/core";
import styles from "./styles/TeamsCallbackStyles";
import { withStyles } from "@material-ui/styles";
import Notifications from "react-notify-toast";
import { notify } from "react-notify-toast";

const api = Api.create();

let STRIPE_API_KEY = "pk_live_knH2EsHeP7lLsWKbC9qJzo1900cXapu198";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("Using test Stripe key");
  STRIPE_API_KEY = "pk_test_eFQAnu73Ssa1wcFzCYEAkbMd007TUMk7Ka";
}

class TeamsCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      success: false,
      message: ""
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const clientId = params.client;
    api.getClientById(clientId, (res) => {
      if (res.status === 200) {
        let client = res.data;
        let success = true;
        let message = "";
        if (client.billingDetails.currentPlan === "paid") {
          success = false;
          message =
            "You're on a paid plan. Contact support@hallway.chat with any questions";
        }
        this.setState({
          clientId: client._id,
          success: success,
          message: message
        });
      } else {
        this.setState({
          success: false,
          message: "Failed to connect. Please contact support@hallway.chat"
        });
      }
    });
  }

  openStripeCheckout = () => {
    var stripe = window.Stripe(STRIPE_API_KEY);

    api.getSessionId(this.state.clientId, (res) => {
      if (res.status === 200) {
        if (!res.data) {
          notify.show(
            "Payment error. Please try again or contact support@hallway.chat",
            "error",
            3000
          );
          return;
        }
        let sessionId = res.data;

        stripe
          .redirectToCheckout({
            sessionId: sessionId
          })
          .then(function (result) {
            console.log(result);
          });
      } else {
        this.setState({
          success: false,
          message: "Failed to connect. Please contact support@hallway.chat"
        });
      }
    });
  };

  goHome = () => {
    window.open("https://hallway.chat");
  };

  render() {
    const { classes } = this.props;
    let { message, success } = this.state;
    return (
      <Container maxWidth="lg" className={classes.container}>
        <Notifications />

        <div className={classes.main}>
          {success ? (
            <Fragment>
              <Typography variant="h4" gutterBottom className={classes.title}>
                For more video chat rooms, channels and users, click Upgrade and
                checkout. $2/mo/user in channel and $30/mo minimum. For volume
                discounts, contact support@hallway.chat.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={this.openStripeCheckout}
              >
                Upgrade
              </Button>
              <Button style={{ marginLeft: 10 }} onClick={this.goHome}>
                Go home
              </Button>
              {/* <div className={classes.splashImageContainer}>
                <div className={classes.iframeContainer}>
                  <iframe
                    src="https://www.loom.com/embed/52cd49b0961543608cba4e049b57d397?autoplay=1"
                    title="demo video"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    className={classes.iframe}
                  ></iframe>
                </div>
              </div> */}
            </Fragment>
          ) : (
            <Typography variant="h4" gutterBottom className={classes.title}>
              {message}
            </Typography>
          )}
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(TeamsCallback);
