import React, { Component } from "react";
import Api from "../Services/Api";
import queryString from "query-string";
import VideoCallFrame from "./VideoCallFrame";

const api = Api.create();

class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      hallywayRoom: false,
      expiryDate: null,
      showErrorMessage: false
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const destination = params.destination;
    const clientId = params.clientId;
    const channelId = params.channelId;

    api.linkClicked(clientId, channelId, (res) => {
      if (res.status === 200) {
        if (destination.includes("https://hallwaychat.daily.co")) {
          this.setState({ url: destination, hallwayRoom: true });
        } else {
          window.location.replace(destination);
        }
      } else {
        this.setState({ showErrorMessage: true });
      }
    });
  }

  render() {
    let { showErrorMessage, url, hallwayRoom } = this.state;
    if (showErrorMessage) {
      return <div>Hmmm... this link doesn't seem valid</div>;
    }
    return <div>{hallwayRoom && <VideoCallFrame url={url} />}</div>;
  }
}

export default Room;
