const styles = {
    container: {
      height: "100vh"
    },
    main: {
      textAlign: "center",
      marginTop: 100
    },
    title: {
      marginBottom: 50
    }
  };
  
  export default styles;