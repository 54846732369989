// a library to wrap and simplify api calls
import apisauce from "apisauce";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// our "constructor"
const create = (baseURL = SERVER_URL) => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache"
    },
    // 10 second timeout...
    timeout: 10000
  });

  const createApiCall = (endpoint, params, callback) => {
    api.post("/api/" + endpoint, params).then((res) => {
      callback(res);
    });
    console.log("/" + endpoint);
  };

  const registerUser = (callback) => {
    createApiCall("registerclient", {}, callback);
  };

  const saveCard = (stripeToken, clientId, callback) => {
    createApiCall(
      "savecard",
      {
        stripeToken: stripeToken,
        clientId: clientId
      },
      callback
    );
  };

  const subscribetoplan = (clientId, callback) => {
    createApiCall("subscribetoplan", { clientId }, callback);
  };

  const unsubscribetoplan = (callback) => {
    createApiCall("unsubscribetoplan", {}, callback);
  };

  const getClientAccount = (callback) => {
    createApiCall("clientaccount", {}, callback);
  };

  const getSessionId = (clientId, callback) => {
    createApiCall("getsessionid", { clientId }, callback);
  };

  const linkClicked = (clientId, channelId, callback) => {
    createApiCall("linkclicked", { clientId, channelId }, callback);
  };

  const connectToSlack = () => {
    window.open(SERVER_URL + "/api/connecttoslack");
  };

  const createSlackAccessToken = (code, plan, callback) => {
    createApiCall("createslackaccesstoken", { code, plan }, callback);
  };

  const updateRoomExpiry = (roomName, callback) => {
    createApiCall("updateroomexpiry", { roomName }, callback);
  };

  const getRoomDetails = (roomName, callback) => {
    createApiCall("getroomdetails", { roomName }, callback);
  };

  const getClientById = (clientId, callback) => {
    createApiCall("getclientbyid", { clientId }, callback);
  };
  return {
    registerUser,
    saveCard,
    subscribetoplan,
    getClientAccount,
    getSessionId,
    unsubscribetoplan,
    connectToSlack,
    linkClicked,
    createSlackAccessToken,
    updateRoomExpiry,
    getRoomDetails,
    getClientById
  };
};

export default {
  create
};
