import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import colors from "./Themes/Colors";
import SlackCallback from "./Components/SlackCallback";
import PaymentCallback from "./Components/PaymentCallback";
import Room from "./Components/Room";
import TagManager from 'react-gtm-module'
import TeamsCallback from "./Components/TeamsCallback";

const tagManagerArgs = {
  gtmId: 'GTM-5XQP2WC'
}

TagManager.initialize(tagManagerArgs)

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(",")
  },
  palette: {
    secondary: {
      main: colors.secondary
    },
    primary: {
      main: colors.primary
    }
  }
});

class Root extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/slackcallback" component={SlackCallback} />
            <Route exact path="/paymentsuccess" component={PaymentCallback} />
            <Route exact path="/room" component={Room} />
            <Route exact path="/teamscallback" component={TeamsCallback} />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default Root;
