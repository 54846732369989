import React, { Component, Fragment } from "react";
import queryString from "query-string";
import Api from "../Services/Api";
import { Button, Container, Typography } from "@material-ui/core";
import styles from "./styles/SlackCallbackStyles";
import { withStyles } from "@material-ui/styles";
import Notifications from "react-notify-toast";
import { notify } from "react-notify-toast";

const api = Api.create();

let STRIPE_API_KEY = "pk_live_knH2EsHeP7lLsWKbC9qJzo1900cXapu198";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("Using test Stripe key");
  STRIPE_API_KEY = "pk_test_eFQAnu73Ssa1wcFzCYEAkbMd007TUMk7Ka";
}

class SlackCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      message: "Loading...",
      success: true
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const code = params.code;
    const plan = params.state;

    api.createSlackAccessToken(code, plan, (res) => {
      if (res.status === 200) {
        if (res.data) {
          this.setState({
            clientId: res.data.clientId,
            message:
              "Connected to " +
              res.data.name +
              "! Go to Slack and add @hallway to a channel to get started"
          });
        }
      } else {
        this.setState({
          success: false,
          message: "Failed to connect. Please contact support@hallway.chat"
        });
      }
    });
  }

  openStripeCheckout = () => {
    var stripe = window.Stripe(STRIPE_API_KEY);

    api.getSessionId(this.state.clientId, (res) => {
      if (res.status === 200) {
        if (!res.data) {
          notify.show(
            "Payment error. Please try again or contact support@hallway.chat",
            "error",
            3000
          );
          return;
        }
        let sessionId = res.data;

        stripe
          .redirectToCheckout({
            sessionId: sessionId
          })
          .then(function (result) {
            console.log(result);
          });
      }
    });
  };

  goHome = () => {
    window.open("https://hallway.chat");
  };

  render() {
    const { message, success } = this.state;
    const { classes } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Notifications />

        <div className={classes.main}>
          <Typography variant="h3" gutterBottom className={classes.title}>
            {message}
          </Typography>
          {success && (
            <Fragment>
              <Typography variant="h6" gutterBottom className={classes.title}>
                For more video chat rooms, channels and users, click Upgrade and
                checkout. $2/mo/user in channel and $30/mo minimum. For volume
                discounts, contact support@hallway.chat.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={this.openStripeCheckout}
              >
                Upgrade
              </Button>
              <Button style={{ marginLeft: 10 }} onClick={this.goHome}>
                Go home
              </Button>
              <div className={classes.splashImageContainer}>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "75%",
                    height: 0
                  }}
                >
                  <iframe
                    src="https://www.loom.com/embed/52cd49b0961543608cba4e049b57d397?autoplay=1"
                    title="demo video"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                  ></iframe>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(SlackCallback);
