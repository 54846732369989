const styles = {
  container: {
    height: "100vh"
  },
  main: {
    textAlign: "center",
    marginTop: 100
  },
  title: {
    marginBottom: 50
  },
  splashImageContainer: {
    margin: "0 auto",
    paddingTop: 43,
    textAlign: "center"
  },
  iframeContainer: {
    position: "relative",
    paddingBottom: "75%",
    height: 0
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
};

export default styles;
