import React, { Component } from "react";
import DailyIframe from "@daily-co/daily-js";
import { Typography, Button, CircularProgress, Grid } from "@material-ui/core";
import Api from "../Services/Api";
import moment from "moment";
import Page404 from "./Page404";
import Countdown from "react-countdown";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import styles from "./styles/VideoCallFrameStyle";

const api = Api.create();

const ONE_MINUTE = 60000;

class VideoCallFrame extends Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
    this.timeout = null;
    this.state = {
      roomName: "",
      room: null,
      roomExpired: false,
      loading: false,
      extendButtonClicked: false,
      showCountDown: false
    };
  }

  componentDidMount() {
    console.log(this.props.url);
    if (!this.props.url) {
      console.error("Didn't get a URL");
      return;
    }
    let url = new URL(this.props.url);

    let roomName = url.pathname.replace("/", "");
    this.setState({ roomName: roomName }, () => {
      this.daily = DailyIframe.wrap(this.iframeRef.current);
      this.getRoomDetails();
    });
  }

  joinRoom = () => {
    this.daily.join({ url: this.props.url, showLeaveButton: true });
    this.daily
      .on("loading", this.showEvent)
      .on("loaded", this.showEvent)
      .on("started-camera", this.showEvent)
      .on("camera-error", this.showEvent)
      .on("joining-meeting", this.showEvent)
      .on("joined-meeting", this.showEvent)
      .on("left-meeting", this.showEvent)
      .on("participant-joined", this.showEvent)
      .on("participant-updated", this.showEvent)
      .on("participant-left", this.showEvent)
      .on("recording-started", this.showEvent)
      .on("recording-stopped", this.showEvent)
      .on("recording-stats", this.showEvent)
      .on("recording-error", this.showEvent)
      .on("recording-upload-completed", this.showEvent)
      .on("app-message", this.showEvent)
      .on("input-event", this.showEvent)
      .on("error", this.getRoomDetails);
  };

  showEvent = (e) => {
    // console.log("video call event -->", e);
  };

  getRoomDetails = () => {
    console.log(this.state.roomName);
    api.getRoomDetails(this.state.roomName, (res) => {
      if (res.status === 200) {
        this.setState(
          { room: res.data, loading: false, showCountDown: false },
          () => {
            console.log(res.data);

            let dateExpired = moment
              .unix(this.state.room.config.exp)
              .isBefore(moment());

            if (!dateExpired) {
              this.setCountdown();
              this.joinRoom();
            }
          }
        );
      }
      if (res.status === 400) {
        this.setState({ roomExpired: true, loading: false });
      }
    });
  };

  setCountdown = () => {
    console.log("setting count down");
    let timestampOfMinuteBeforeExpiration = this.state.room.config.exp - 60;
    let waitTime = (timestampOfMinuteBeforeExpiration - moment().unix()) * 1000;
    this.timeout = setTimeout(() => {
      console.log("show count down");
      this.setState({ showCountDown: true });
    }, waitTime);
  };

  extendExpiryClicked = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    api.updateRoomExpiry(this.state.roomName, (res) => {
      if (res.status === 200) {
        this.setState(
          { room: res.data, showCountDown: false, extendButtonClicked: true },
          () => {
            let dateExpired = moment
              .unix(this.state.room.config.exp)
              .isBefore(moment());

            if (!dateExpired) {
              this.setCountdown();
            }
          }
        );
      }
    });
  };

  render() {
    let {
      extendButtonClicked,
      roomExpired,
      loading,
      showCountDown
    } = this.state;
    const { classes } = this.props;

    if (loading) {
      return (
        <Grid
          container
          direction="column"
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress size={30} />
        </Grid>
      );
    }

    if (roomExpired) {
      return <Page404 errorMessage="This break room already ended 😔 but you can type '/hallway break' in Slack to start a new one" />;
    }
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end"
        }}
      >
        {showCountDown && (
          <div className={classes.extendContainer}>
            {!extendButtonClicked && (
              <Button
                style={{
                  display: extendButtonClicked ? "none" : "block"
                }}
                color="primary"
                onClick={() => this.extendExpiryClicked()}
              >
                Extend break
              </Button>
            )}
            <Countdown
              date={Date.now() + ONE_MINUTE}
              renderer={(props) => (
                <Typography variant="h3" className={classes.countdown}>
                  {props.seconds}
                </Typography>
              )}
            />
          </div>
        )}

        <iframe
          style={{ height: "95%", width: "100%" }}
          className="Video-Frame"
          title="video call iframe"
          ref={this.iframeRef}
          allow="camera; microphone; fullscreen"
        ></iframe>
      </div>
    );
  }
}

VideoCallFrame.propTypes = {
  url: PropTypes.string.isRequired
};

export default withStyles(styles)(VideoCallFrame);
