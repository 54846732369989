import React, { Component } from "react";
import { Button, Container, Typography } from "@material-ui/core";
import styles from "./styles/SlackCallbackStyles";
import queryString from "query-string";
import { withStyles } from "@material-ui/styles";

class PaymentCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failed: false
    };
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);

    if (params.failed) {
      this.setState({ failed: params.failed });
    }
  }

  goHome = () => {
    window.open("https://hallway.chat");
  };

  render() {
    const { failed } = this.state;
    const { classes } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.main}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            {failed
              ? "Failed to subscribe"
              : "Successfully subscribed to Hallway"}
          </Typography>
          <Typography variant="h4" gutterBottom className={classes.title}>
            Contact support@hallway.com if you have any questions about your
            subscription including cancellation
          </Typography>
          <Button onClick={this.goHome}>Go home</Button>
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(PaymentCallback);
