import grey from "@material-ui/core/colors/grey";

const styles = {
  countdown: {
    color: grey[700],
    marginLeft: 10,
    marginRight: 10,
    textAlign: "center",
    width: 60
  },
  extendContainer: {
    display: "flex",
    flexDirection: "row"
  }
};

export default styles;
